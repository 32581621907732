<template>
  <div>
    <b-modal
      v-model="modalShow"
      centered
      size="lg"
      :title="`Block games to customer ${customer.name}`"
      hide-footer
    >
      <b-card
        id="nav-scroller"
        ref="content"
        style="position: relative; height: 600px; overflow-y: scroll"
        no-body
      >
        <b-list-group v-if="!productSelectId">
          <b-list-group-item
            active
            class="d-flex justify-content-between align-items-center"
          >
            <span>Select a product to see the games</span>

            <div>Games</div>
          </b-list-group-item>
           <div class="m-2 text-center" v-if="productsOptions && !productsOptions.length >= 1">
                <h4>The player has no games</h4>
            </div>

      
          <b-list-group-item
           v-else
            v-for="(tr, index) in productsOptions"
            :key="index"
            class="d-flex justify-content-between align-items-center cursor-pointer"
            @click="selectProduct(tr._id, tr.name)"
          >
          <!-- <b-overlay :show="tr.loading" variant="transparent" no-wrap /> -->
            <span>{{ tr.name }}</span>

            <div>
              <b-badge
                pill
                :variant="`light-${tr.isgame ? 'secondary' : 'primary'}`"
                class="text-capitalize"
              >
                {{
                  tr.isgame
                    ? "Product game"
                    : `${tr.gamesTotal} games`
                }}
              </b-badge>
            </div>

            <!-- <b-form-checkbox
              :checked="tr.status"
              v-model="tr.status"
              class="custom-control-danger"
              name="check-button"
              switch
              @change="blockGame(tr.gameId, tr.status, tr)"
            >
              <span class="switch-icon-left">
                <feather-icon icon="LockIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="UnlockIcon" />
              </span>
            </b-form-checkbox> -->

          </b-list-group-item>
        </b-list-group>


        <!-- cuando se tiene seleccionado un producto se puede ver la lista de juegos -->
         <b-list-group v-if="productSelectId" >
           <b-overlay :show="loadingDataGames" variant="transparent" no-wrap/>
           <b-list-group-item
            active
            class="d-flex justify-content-between align-items-center"
          >
          <div>
            <b-button
              variant="light"
              class="mr-1 btn-icon pt-0 pl-0 rounded-circle"
              size="sm"
              @click="productSelectId = ''"
            >
              <feather-icon icon="ArrowLeftIcon" />
            </b-button>
            <span class="text-bold">{{productName}} games </span>
          </div>

            <div class="mr-1">Status</div>
          </b-list-group-item>

          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
          >
          <!-- searh -->
          <BRow class="w-100">
            <BCol md="6">
              <b-overlay  :show="loadingDataGames" rounded="lg" opacity="0.6">
                <template v-slot:overlay>
                  <div class="d-flex align-items-center">
                    <b-spinner small type="grow" variant="secondary" />
                    <b-spinner type="grow" variant="dark" />
                    <b-spinner small type="grow" variant="secondary" />
                    <span class="sr-only">Please wait...</span>
                  </div>
                </template>

                <v-select
                  class="mb-1"
                  v-model="brandId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="brandsOptions"
                  label="name"
                  @input="brandSelectInput"
                  placeholder="Select brand"
                >
                </v-select>
              </b-overlay>
            </BCol>
            <BCol md="6">
              <b-form-input
              v-model="searchGames"
              placeholder="Search games"
            ></b-form-input>
            </BCol>

            <BCol md="6">
              <b-button
                v-if="productSelectId"
                :variant="providerLockedCustomer  ? 'outline-success' : 'outline-danger'"
                @click="desactiveActiveGames('products')"
                class="d-inline-block mb-1 mr-1"
                size="sm"
              >
                <feather-icon :icon="providerLockedCustomer  ? 'CheckCircleIcon' : 'XCircleIcon' " />
                <span class="align-middle ml-25">{{providerLockedCustomer  ? 'Activar' : 'Desactivar'}} todo el proveedor</span>
              </b-button>
            </BCol>
            <BCol md="6">
                <b-button
                  v-if="brandId !== ''"
                  :variant="brandLockedCustomer  ? 'outline-success' : 'outline-danger'"
                  @click="desactiveActiveGames('brands')"
                  class="d-inline-block mb-1"
                  size="sm"
                >
                  <feather-icon :icon="brandLockedCustomer  ? 'CheckCircleIcon' : 'XCircleIcon' " />
                  <span class="align-middle ml-25">{{brandLockedCustomer  ? 'Activar' : 'Desactivar'}} toda la marca</span>
                </b-button>
              </BCol>
          </BRow>
            
          </b-list-group-item>

        <b-list-group-item
            v-for="(tr, index) in gamesProductSelectOptions"
            :key="index"
            class="d-flex justify-content-between align-items-center"
          >
          
            <span>{{ tr.gameName }}</span>
            <b-form-checkbox
              :checked="tr.gameLocked"
              class="custom-control-danger"
              name="check-button"
              switch
              @change="blockGame(tr)"
            >
              <span class="switch-icon-left">
                <feather-icon icon="LockIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="UnlockIcon" />
              </span>
            </b-form-checkbox>
          </b-list-group-item>
        </b-list-group>

        <section style="padding: 30px 0 0px 0px;">
        <b-row>
          <b-col cols="12">
            <b-pagination
              v-model="current_page"
              :total-rows="total"
              :per-page="per_page"
              first-number
              @change="getGamesProduct()"
              align="right"
              last-number
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" /> 
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </section>
        

      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BFormCheckbox,
  BListGroup,
  BListGroupItem,
  BCard,
  BOverlay,
  BBadge,
  BFormInput,
  BPagination,
  BRow,
  BCol,
  BSpinner
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import gamesStoreModule from "@/store/games";
import store from "@/store";
import vSelect from "vue-select";

export default {
  components: {
    BButton,
    BModal,
    BFormCheckbox,
    BListGroup,
    BListGroupItem,
    BCard,
    BOverlay,
    BBadge,
    BFormInput,
    BPagination,
    BRow,
    BCol,
    vSelect,
    BSpinner
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    customer: {
      type: Object,
      default: () => {},
    },
    customers: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    modalShow: false,
    searchQueryProduct: "",
    loadingDataGames: false,
    gamesProductSelect: [],
    productSelectId: "",
    searchGames: "",
    productName: "",

    current_page: 1,
    total: 0,
    per_page: 10,

    loadingDataBrands: false,
    brandId: "",
    brandsOptions: [],

    brandLockedCustomer: false,
    providerLockedCustomer: false,
  }),
  watch:{
      modalShow(val){
          if(val){
              // this.user.tokens.map(p => p.loading = false )
              // this.$store.dispatch('moduleProducts/getProducts')
          }else{
            this.productSelectId = ""
            this.searchGames = ""
          }
      },
  },
  computed: {
    productsOptions(){
      return  this.customer?.products?.filter((product) => {
        return product.name.toLowerCase().includes(this.searchQueryProduct.toLowerCase())
      })
    },
    gamesProductSelectOptions(){
      if(this.gamesProductSelect.length == 0) return []

      let gamesList =  this.gamesProductSelect.filter((game) => {
        return game.gameName.toLowerCase().includes(this.searchGames.toLowerCase())
      })

      gamesList = gamesList.map(p => {
        p.gameLocked = this.validGameBlock(p._id)
        return p
      })
      return gamesList
    },
    customerSelect(){
      let customerSelect = this.customers.customers.find(p => p._id == this.customer._id)

      if(customerSelect){
        return customerSelect
      }
      
      return {}
    }
  },

  methods: {
    validGameBlock(_id){
      return this.customerSelect?.restrictedGames?.includes(_id) ? true : false
    },
    async blockGame({gameId, _id}) {
      const obj = {
        objectIdCustomer: this.customer._id,
        gameId: gameId,
        status: this.validGameBlock(_id)
      };
      //false para bloqueo
      try{
          // tr.loading = true
         await this.$store.dispatch("customersModule/blockGame", obj);

         if(!this.customer.restrictedGames){
            this.customer.restrictedGames = []
          }

         if(!obj.status){
           this.customer.restrictedGames.push(_id)
          }else{
            this.customer.restrictedGames = this.customer.restrictedGames.filter(p => p != _id)
          }

          // emit updateListCustomer
          this.$emit('updateListCustomer')

        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${obj.status ? 'Game successfully unlocked' : 'Game blocked successfully'}`,
            icon: `${obj.status ? 'UnlockIcon' : 'LockIcon'}`,
            variant: `${obj.status ? 'success' : 'success'}`,
          },
        });
      } catch (error) {
        console.log(error);
        // validar si el error es 403
        if (error.response.status == 403) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `${error.response.data.message}`,
            },
          });
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: `An error occurred, please contact the administrator`,
            },
          });
        }
        
      }finally{
          // tr.loading = false
      }
    },
    selectProduct(productId, productName){
      this.productSelectId =  productId
      this.productName = productName
      this.brandId = ""
      this.getGamesProduct(productId)
      this.getBrands()
    },
     async getGamesProduct(productId) {
      try {
        this.loadingDataGames = true;
        const res = await this.$store.dispatch(
          "gamesModule/getGames",
          {
            producSelectId: productId || this.productSelectId,
            params: {
              page: this.current_page,
              perPage: this.per_page,
              brandId: this.brandId?.brandId,
              customerId: this.customer._id
            },
          }
        );
        this.gamesProductSelect = res.data.games;
        this.total = res.data.count;

        this.brandLockedCustomer = res.data.infoCustomer.brandLockedCustomer
        this.providerLockedCustomer = res.data.infoCustomer.providerLockedCustomer
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching games list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      } finally {
        this.loadingDataGames = false;
      }
    },
    brandSelectInput($event){
      this.brandObjectId = $event 
      this.getGamesProduct()
    },
    async getBrands(productId) {
      try {
        const dataBrands = await this.$store.dispatch("brandModule/index", productId || this.productSelectId);
        this.brandsOptions = dataBrands.data.brands
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching brands list",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      }
    },
    async desactiveActiveGames(type) {
      try {
        const obj = {
          productId: this.productSelectId,
          brandId: this.brandId?._id,
          customerId: this.customer._id,
          operation: type == 'products' ? 
          this.providerLockedCustomer ? 'activate'  : 'inactive'
          : 
          this.brandLockedCustomer ? 'activate'  : 'inactive',
          type: type,
        }
        await this.$store.dispatch("gamesModule/desactiveActiveGames", obj);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `${obj.operation ? 'Games successfully unlocked' : 'Games blocked successfully'}`,
            icon: `${obj.operation ? 'UnlockIcon' : 'LockIcon'}`,
            variant: `${obj.operation ? 'success' : 'success'}`,
          },
        });
        this.getGamesProduct()
        this.$emit('updateListCustomer')
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            icon: "AlertTriangleIcon",
            variant: "danger",
            text: `${error.response.data.message}`,
          },
        });
      }
    },
  },
   created() {
    // Register module
    if (!store.hasModule("gamesModule"))
      store.registerModule("gamesModule", gamesStoreModule);
  },
  destroyed() {
    if (store.hasModule("gamesModule")) store.unregisterModule("gamesModule");
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
</style>
